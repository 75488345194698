import { createElement, Component } from 'react';
import { guard } from 'bv-services/features';

export default (featureName) => (component) => {
  class WithFeatureComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        featureResolved: false,
      };
    }

    componentDidMount() {
      guard(featureName).then((enabled) => (
        this.setState({
          featureResolved: true,
          featureEnabled: enabled,
        })
      ));
    }

    render() {
      return createElement(component, { ...this.props, ...this.state });
    }
  }

  WithFeatureComponent.displayName = 'withFeatureComponent';

  return WithFeatureComponent;
};
