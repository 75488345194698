import { memo, createElement } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Arrow from './arrow';

const Carousel = memo(({
  canScrollLeft,
  canScrollRight,
  carouselWrapElRef,
  carouselElRef,
  children,
  className,
  embedded,
  displayArrows,
  displayFixedArrows,
  scroll,
  onScroll,
  largeArrows,
  sliderId,
  dangerouslySetInnerHTML,
  sliderTagName,
  sliderClassName,
}) => {
  const carouselClassName = classnames('bvs-carousel', {
    'bvs-carousel--with-arrows': displayArrows,
    'bvs-carousel--embedded': embedded,
    'bvs-carousel--with-fixed-arrows': displayFixedArrows,
    'bvs-carousel--with-large-arrows': largeArrows,
  }, className);

  return (
    <div className={carouselClassName} ref={carouselWrapElRef}>
      {displayArrows && <Arrow direction="left" disabled={!canScrollLeft} onClick={scroll(-1)} />}
      {createElement(
        sliderTagName,
        {
          className: `bvs-carousel__slider${sliderClassName ? ` ${sliderClassName}` : ''}`,
          ref: carouselElRef,
          onScroll,
          id: sliderId,
          dangerouslySetInnerHTML,
        },
        // dangerouslySetInnerHTML and children can't be used together
        dangerouslySetInnerHTML ? null : children,
      )}
      {displayArrows && <Arrow direction="right" disabled={!canScrollRight} onClick={scroll(1)} />}
    </div>
  );
});

Carousel.propTypes = {
  canScrollLeft: PropTypes.bool.isRequired,
  canScrollRight: PropTypes.bool.isRequired,
  carouselWrapElRef: PropTypes.instanceOf(Object).isRequired,
  carouselElRef: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  embedded: PropTypes.bool,
  displayArrows: PropTypes.bool.isRequired,
  displayFixedArrows: PropTypes.bool,
  largeArrows: PropTypes.bool,
  scroll: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
  sliderId: PropTypes.string,
  dangerouslySetInnerHTML: PropTypes.instanceOf(Object),
  sliderClassName: PropTypes.string,
  sliderTagName: PropTypes.string,
};

Carousel.defaultProps = {
  sliderClassName: '',
  sliderTagName: 'div',
  embedded: false,
  sliderId: null,
  dangerouslySetInnerHTML: null,
  className: null,
  displayFixedArrows: null,
  largeArrows: false,
};

Carousel.displayName = 'Carousel';

export default Carousel;
