import PropTypes from 'prop-types';
import { targetProp } from '../../../app/layout_apps/left_components/helpers/helpers';
import Link from '../link';

const TabView = ({
  label,
  className,
  href,
  onClick,
  tagName: Tag,
  activeRef,
}) => {
  const Component = href ? Link : 'div';

  return (
    <Tag className={className} ref={activeRef}>
      <Component
        className="site-main-nav__item--link bvs-link"
        {...targetProp(href)}
        to={href?.replace(/.?open_in_new_tab=1$/g, '')}
        onClick={onClick}
      >
        {label}
      </Component>
    </Tag>
  );
};

TabView.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  tagName: PropTypes.string,
  activeRef: PropTypes.func,
};

TabView.defaultProps = {
  tagName: 'li',
  href: null,
  activeRef: null,
};

export default TabView;
