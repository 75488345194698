import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Carousel from '../carousel';
import Tab from '../tab';

const TabsMenu = ({ tabs }) => {
  const [scrollTo, setScrollTo] = useState(null);
  const handleActiveRef = useCallback((tab) => {
    if (tab) {
      setScrollTo(tab);
    }
  });

  return (
    <div className="site-main-nav-wrapper">
      <Carousel
        className="site-main-nav"
        scrollStep={234}
        sliderTagName="ul"
        displayFixedArrows
        scrollTo={scrollTo}
      >
        {tabs.map(({ className, ...rest }) => (
          <Tab
            className={classnames('site-main-nav__item', className)}
            activeRef={handleActiveRef}
            key={rest.id}
            {...rest}
          />
        ))}
      </Carousel>
    </div>
  );
};

TabsMenu.propTypes = {
  tabs: PropTypes.instanceOf(Array),
};

TabsMenu.defaultProps = {
  tabs: [],
};

export default TabsMenu;
