import PropTypes from 'prop-types';
import classnames from 'classnames';
import { omit } from 'underscore';
import Link from './link';

import getClassNameFromType from './button/helpers/get_class_name_from_type';

const getBaseClass = (noClass, inverse) => {
  if (noClass) {
    return '';
  }

  return inverse ? 'bvs-button-alternative' : 'bvs-button';
};

const getButtonElement = (href, to) => {
  let element;
  if (href) {
    element = 'a';
  } else if (to) {
    element = Link;
  } else {
    element = 'button';
  }
  return element;
};

const Button = ({
  className: classNameProp,
  invisible,
  primary,
  secondary,
  info,
  warning,
  danger,
  disabled,
  default: isDefault,
  href,
  to,
  ref,
  onClick,
  dangerouslySetInnerHTML,
  target,
  rel,
  type,
  label,
  active,
  children,
  badge,
  loading,
  noClass,
  inverse,
  dataReact,
  testId,
  ...rest
}) => {
  const className = classnames(classNameProp, getBaseClass(noClass, inverse), {
    'is-invisible': invisible,
    'is-primary': primary,
    'is-secondary': secondary,
    'is-info': info,
    'is-warning': warning,
    'is-danger': danger,
    disabled,
    'is-default': isDefault,
    active,
    'with-badge': badge,
  }, getClassNameFromType(type));

  const elementProps = {
    className,
    onClick,
    href,
    to,
    target,
    rel,
    type,
    ref,
    ...(dataReact ? {
      'data-react': true,
    } : {}),
    ...(dangerouslySetInnerHTML ? {
      dangerouslySetInnerHTML,
    } : {}),
    ...omit(rest, 'dataReact', 'multiline', 'noClass'),
  };

  const Element = getButtonElement(href, to);

  // Children cannot be used together with dangerouslySetInnerHTML
  if (dangerouslySetInnerHTML) {
    return <Element {...elementProps} />;
  }

  return (
    <Element
      data-testid={testId}
      {...elementProps}
    >
      {label || children}
      {loading && <span className="bvs-spinner is-light loading" />}
      {badge && (
        <div className="bvs-button-chip__badge">
          {badge}
        </div>
      )}
    </Element>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  dangerouslySetInnerHTML: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  invisible: PropTypes.bool,
  inverse: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  default: PropTypes.bool,
  loading: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.string,
  ref: PropTypes.string,
  className: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  type: PropTypes.string,
  noClass: PropTypes.bool,
  active: PropTypes.bool,
  dataReact: PropTypes.bool,
  children: PropTypes.node,
  badge: PropTypes.number,
  testId: PropTypes.string,
};

Button.defaultProps = {
  onClick: () => {},
  dangerouslySetInnerHTML: null,
  invisible: false,
  inverse: false,
  primary: false,
  secondary: false,
  info: false,
  warning: false,
  danger: false,
  disabled: false,
  default: false,
  loading: false,
  href: null,
  to: null,
  ref: null,
  className: '',
  target: null,
  rel: null,
  type: null,
  noClass: false,
  active: false,
  dataReact: false,
  children: '',
  label: null,
  badge: null,
  testId: null,
};

export default Button;
