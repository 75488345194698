import { createElement, PureComponent } from 'react';

import Spinner from 'BVComponents/spinner';

export default (
  fetchMethod,
  collectionName,
  { showSpinner = true, spread = false, forceRender = false } = {},
) => (WrappedComponent) => {
  class WithFetching extends PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        fetched: false,
      };
    }

    componentDidMount() {
      fetchMethod(this.props).then((data) => {
        this.setState({
          data,
          fetched: true,
        });
      });
    }

    render() {
      const { fetched, data } = this.state;

      if (!fetched && !forceRender) {
        return showSpinner ? <Spinner /> : null;
      }

      if (data || forceRender) {
        return createElement(
          WrappedComponent,
          spread ? {
            ...data,
            ...this.props,
            dataFetched: fetched,
          } : {
            [collectionName]: data,
            ...this.props,
            dataFetched: fetched,
          },
        );
      }

      return null;
    }
  }

  WithFetching.displayName = 'withFetching';

  return WithFetching;
};
