// Get distance from top of element to top of the page
const calculateTopPosition = (el) => (
  el ? el.offsetTop + calculateTopPosition(el.offsetParent) : 0
);

// Distance from bottom of viewport to end of element
const distanceToBottom = (el, scrollableTarget) => (
  el ? calculateTopPosition(el) + el.offsetHeight
  - (
    scrollableTarget === window ? window.pageYOffset : scrollableTarget.scrollTop
  )
  - window.innerHeight : 0
);

// Distance to start loading more items (in px)
const scrollOffset = 400;

export const shouldLoadMore = (
  el, scrollableTarget) => distanceToBottom(el, scrollableTarget) < scrollOffset;
