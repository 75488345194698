import { getCookie } from 'bv';
import useFeature from './use_feature';

export default () => {
  const useFeatureResult = useFeature('one_auth');

  if (getCookie('one_auth_testing')) return [true, true];

  return useFeatureResult;
};
