import { useEffect, useContext } from 'react';
import { throttle, isEmpty } from 'underscore';
import ModalWindowContext from '../contexts/modal_window_context';
import { shouldLoadMore } from './helpers/infinite_scroll_helper';

export default (
  useInfiniteScroll, onEndOfElement, listRef, totalItems, options = { throttleSpeed: 300 }) => {
  if (!useInfiniteScroll) return;
  const modal = useContext(ModalWindowContext);

  useEffect(() => {
    const scrollableTarget = !isEmpty(modal) ? modal.scrollableRef.current : window;

    const checkFn = () => {
      if (listRef?.current?.childElementCount < totalItems
          && shouldLoadMore(listRef.current, scrollableTarget)) {
        onEndOfElement();
      }
    };

    checkFn();
    const onElementScroll = throttle(checkFn, options.throttleSpeed);

    scrollableTarget.addEventListener('scroll', onElementScroll);

    return () => {
      scrollableTarget.removeEventListener('scroll', onElementScroll);
    };
  }, [totalItems]);
};
