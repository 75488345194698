import { t } from 'bv-i18n';
import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

const ChatLoading = (props) => {
  const [infoTextIndex, setInfoTextIndex] = useState(0);
  const [loadingTime, setLoadingTime] = useState(0);
  const [loadingFailed, setLoadingFailed] = useState(false);

  const infoTextArr = [
    t('javascript.quick_chat.communicating_with_live_chat'),
    t('javascript.quick_chat.loading_the_live_chat'),
    t('javascript.quick_chat.almost_there'),
  ];

  let timeout;

  useEffect(() => {
    timeout = setTimeout(
      () => {
        setInfoTextIndex((infoTextIndex + 1) % infoTextArr.length);
        setLoadingTime(loadingTime + 1500);
      },
      1500,
    ); // endless effect of dynamic texts

    return () => clearTimeout(timeout);
  }, [infoTextIndex]);

  useEffect(() => {
    if (loadingTime > 30000) {
      setLoadingFailed(true);
      clearTimeout(timeout);
    }
  }, [loadingTime]);

  const { onHideChatPreloader } = props;

  return (
    <div className="live-chat-wrapper">
      <div className="live-chat-loader">
        <div className="live-chat-loader__header">
          {t('live_chat')}
          <span className="live-chat-loader__close bvs-link" onClick={onHideChatPreloader} />
        </div>
        <div className="live-chat-loader__content">
          {loadingFailed ? (
            <p>{t('javascript.quick_chat.error_loading_app')}</p>
          ) : (
            <div className="skeleton is-loading live-chat-loading">
              <div className="skeleton__content">
                <div className="live-chat-loading__skeleton" />
              </div>
              <div className="skeleton__content">
                <div className="live-chat-loading__skeleton" />
              </div>

              <div className="live-chat-loading__icon-wrapper">
                <div className="live-chat-loading__icon" />
              </div>

              <div className="live-chat-loading__msg">{infoTextArr[infoTextIndex]}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ChatLoading.propTypes = {
  onHideChatPreloader: PropTypes.func.isRequired,
};

export default ChatLoading;
