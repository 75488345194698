import { useContext } from 'react';
import PropTypes from 'prop-types';
import RenderInBody from './render_in_body';
import ModalWindowContext from '../contexts/modal_window_context';

const RenderInModal = ({ children, className }) => {
  const { ref } = useContext(ModalWindowContext);

  return (
    <RenderInBody container={ref.current} className={className}>
      {children}
    </RenderInBody>
  );
};

RenderInModal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

RenderInModal.defaultProps = {
  className: '',
};

export default RenderInModal;
