import {
  useState, useEffect, forwardRef, useRef,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import useOnScrollEnd from '../hooks/use_on_scroll_end';

const Child = forwardRef(({ children }, ref) => (
  <div ref={ref}>{children}</div>
));

Child.propTypes = {
  children: PropTypes.node.isRequired,
};

const ExpandCollapseCard = ({ children, contentOverviewHeight, fadeOutOnEnd }) => {
  const [expanded, setExpanded] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const contentRef = useRef();
  const scrollableRef = useRef();

  useOnScrollEnd({
    scrollableRef: fadeOutOnEnd ? scrollableRef : null,
    onEndReach: setFadeOut,
  });

  useEffect(() => {
    if (contentRef?.current.clientHeight > contentOverviewHeight) setShowIcon(true);
  }, []);

  return (
    <div
      className={classnames('has-scroll-bars bvs-card expand-collapse__card', { collapsed: !expanded, expanded, fadeout: fadeOut })}
      ref={scrollableRef}
    >
      <Child ref={contentRef}>{children}</Child>
      { showIcon && (
      <div className="expand-collapse__overlay">
        <div
          className={`bvs-icon bvs-link expand-collapse__icon is-${expanded ? 'collapsed' : 'expanded'}`}
          onClick={() => setExpanded(!expanded)}
        />
      </div>
      )}
    </div>
  );
};

ExpandCollapseCard.defaultProps = {
  contentOverviewHeight: 240,
  fadeOutOnEnd: false,
};

ExpandCollapseCard.propTypes = {
  children: PropTypes.node.isRequired,
  contentOverviewHeight: PropTypes.number,
  fadeOutOnEnd: PropTypes.bool,
};

export default ExpandCollapseCard;
