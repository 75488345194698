import PropTypes from 'prop-types';

const srcPathReplacements = [
  ['/2x/', '/2x_webp/'],
  [/[^.]+$/, 'webp'],
  ['default', 'webp'],
];

const getSrcSet = (src) => srcPathReplacements
  .reduce((srcSet, replacement) => srcSet.replace(...replacement), src);

const WebpWrapper = ({
  webp, src, webpSrc, children,
}) => (webp ? (
  <picture>
    <source srcSet={webpSrc || getSrcSet(src)} type="image/webp" />
    {children}
  </picture>
) : children);

WebpWrapper.propTypes = {
  webp: PropTypes.bool.isRequired,
  webpSrc: PropTypes.string,
  src: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

WebpWrapper.defaultProps = {
  webpSrc: null,
};

export default WebpWrapper;
