import fetchSettings from './api';

export const SET_LIVE_CHAT_SETTINGS = 'banners/SET_LIVE_CHAT_SETTINGS';
export const SET_LOADING_LIVE_CHAT_SETTINGS = 'banners/SET_LOADING_LIVE_CHAT_SETTINGS';

const setLoadingChatSettings = () => ({
  type: SET_LOADING_LIVE_CHAT_SETTINGS,
});

const setLiveChatSettings = (settings) => ({
  type: SET_LIVE_CHAT_SETTINGS,
  settings,
});

export const fetchSettingsThunk = () => (dispatch) => {
  dispatch(setLoadingChatSettings());

  fetchSettings().then((settings) => dispatch(setLiveChatSettings(settings)));
};

const initialState = {
  settings: [],
  loading: false,
  loaded: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LIVE_CHAT_SETTINGS:
      return {
        ...initialState,
        settings: action.settings,
        loading: false,
        loaded: true,
      };
    case SET_LOADING_LIVE_CHAT_SETTINGS:
      return {
        ...initialState,
        loading: true,
      };
    default:
      return state;
  }
};
