import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { compose } from 'underscore';
import { selectLoggedIn } from 'session-selectors';
import { locale as getLocale } from 'bv-i18n';
import { fetchFooterSettings as fetchFooterSettingsDucks } from '../duck';

import SiteFooterView from './site_footer_view';

const SiteFooterContainer = ({
  className,
  tagName,
  isRegPath,
  footerSettings,
  fetchFooterSettings,
  hasLoadedFooterSettings,
}) => {
  const loggedIn = useSelector(selectLoggedIn);
  const locale = getLocale();

  useEffect(() => {
    if (!hasLoadedFooterSettings) {
      fetchFooterSettings(loggedIn, locale);
    }
  }, [loggedIn, locale]);

  const { showRegPath } = footerSettings;
  const isFooterContentVisible = isRegPath ? showRegPath : true;

  return (
    <SiteFooterView
      className={className}
      footerSettings={footerSettings}
      tagName={tagName}
      isFooterContentVisible={isFooterContentVisible}
    />
  );
};

SiteFooterContainer.propTypes = {
  className: PropTypes.string,
  tagName: PropTypes.string,
  isRegPath: PropTypes.bool,
  footerSettings: PropTypes.instanceOf(Object).isRequired,
  fetchFooterSettings: PropTypes.func.isRequired,
  hasLoadedFooterSettings: PropTypes.bool.isRequired,
};

SiteFooterContainer.defaultProps = {
  className: '',
  tagName: 'footer',
  isRegPath: false,
};

const mapStateToProps = (state) => ({
  footerSettings: state.siteFooter,
  hasLoadedFooterSettings: state.siteFooter.hasLoadedFooterSettings,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFooterSettings: compose(dispatch, fetchFooterSettingsDucks),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteFooterContainer);
