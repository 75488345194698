import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEqual, throttle } from 'underscore';
import { isDesktop } from 'bv';

const inputSize = 75;

class SliderSelector extends PureComponent {
  constructor(props) {
    super(props);

    this.listWrap = props.listWrapRef || createRef();
    this.select = createRef();

    this.state = {
      style: {},
      scrolling: false,
    };
  }

  componentDidMount() {
    this.updateSizes();
    window.addEventListener('resize', this.updateSizes);
  }

  // When the style changes, we adjust the scroll position
  componentDidUpdate(prevProps, prevState) {
    const { style, scrolling } = this.state;
    const { selectedId, selections } = this.props;

    const moveToSelected = !isEqual(style, prevState.style)
      || (!isEqual(prevProps.selectedId, selectedId) && !scrolling);

    if (moveToSelected) {
      this.moveToSelected();
    }
    if (!isEqual(selections, prevProps.selections)) {
      this.updateSizes();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSizes);
  }

  // DOM events (onXxx)
  onScroll = throttle(() => {
    const { triggerWhileScrolling } = this.props;

    if (triggerWhileScrolling) {
      // As we scroll, we set the active item
      this.setState({ scrolling: true });
      this.setClosestAsSelected();
    }

    // Detect end of scrolling
    if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(this.onScrollEnd, 75);
  }, 50);

  onScrollEnd = () => {
    const { triggerWhileScrolling } = this.props;

    if (!triggerWhileScrolling) {
      this.setClosestAsSelected();
    } else {
      this.setState({ scrolling: false });
    }

    this.moveToSelected();
  };

  onClick = (event) => {
    this.moveTo(parseInt(event.currentTarget.getAttribute('data-index'), 10));
  };

  onRangeChange = (event) => {
    this.moveTo(event.target.value);
  };

  // Get the active element index based in scroll position
  getClosestElementIndex() {
    const { selections } = this.props;

    // For same reason, some iOS browsers allow you to scroll more than the size
    return Math.max(
      Math.min(
        selections.length,
        Math.round(this.listWrap.current.scrollLeft / inputSize) + 1,
      ),
      1,
    ) - 1;
  }

  getTrackPercentage() {
    const { selections } = this.props;

    const selectedIndex = this.selectedIndex();
    const selectionsLength = selections.length - 1;

    if (selectionsLength) {
      return (selectedIndex / selectionsLength) * 100;
    }

    return 0;
  }

  setClosestAsSelected() {
    const selectedIndex = this.getClosestElementIndex();

    if (selectedIndex !== this.selectedIndex()) {
      this.setSelected(selectedIndex);
    }
  }

  // We simulate the selection in the select element and update the state accordingly
  setSelected(selectedIndex) {
    const { selections } = this.props;

    const selectedId = selections[selectedIndex].id;

    window.BrowserEvent = window.BrowserEvent || window.Event;
    this.select.current.value = selectedId;
    this.select.current.dispatchEvent(new window.BrowserEvent('change', { bubbles: true }));
  }

  updateSizes = () => {
    const { selections } = this.props;

    if (!this.listWrap.current) return;

    this.setState({
      style: {
        width: `${selections.length * inputSize}px`,
        padding: `0 ${this.listWrap.current.clientWidth / 2 - inputSize / 2}px`,
      },
    });
  };

  moveToSelected = () => {
    this.moveTo(this.selectedIndex());
  };

  // Scroll the list to an index
  moveTo(index) {
    const scrollLeft = index * inputSize;

    if (this.listWrap.current.scrollTo) {
      this.listWrap.current.scrollTo({ left: scrollLeft, top: 0, behavior: 'smooth' });
    } else {
      this.listWrap.current.scrollLeft = scrollLeft;
    }
  }

  selectedIndex() {
    const { selections, selectedId } = this.props;

    return selections.findIndex((selection) => selection.id === selectedId);
  }

  render() {
    const {
      className,
      primary,
      big,
      selectedId,
      name,
      onChange,
      selections,
      isWiderItem,
    } = this.props;

    const { style } = this.state;

    const sliderClassName = classnames('bvs-slider-selector', className, {
      'is-primary': primary,
      'bvs-slider-selector--big': big,
    });

    const sliderWrapClassName = classnames('bvs_slider-selector__wrap', {
      'is-wider-item': isWiderItem,
    });

    return (
      <div className={sliderClassName}>
        <select
          ref={this.select}
          value={selectedId}
          className="bvs-none"
          name={name}
          onChange={onChange}
        >
          {selections.map(({ id, title }) => (
            <option value={id} key={id} selected={selectedId === id}>
              {title}
            </option>
          ))}
        </select>

        <div className={sliderWrapClassName}>
          <div
            ref={this.listWrap}
            className="bvs-slider-selector__list-wrap"
            onScroll={this.onScroll}
          >
            <ul className="bvs-slider-selector__list" style={style}>
              {selections.map(({ id, title }, index) => (
                <li
                  onClick={this.onClick}
                  className={classnames('bvs-slider-selector__item', {
                    'bvs-slider-selector__item--active': selectedId === id,
                  })}
                  data-index={index}
                  key={id}
                >
                  {title}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {isDesktop() && (
          <div className="bvs-slider-selector__range-wrapper">
            <input
              type="range"
              min="0"
              max={selections.length - 1}
              value={this.selectedIndex()}
              className="bvs-slider-selector__range"
              onChange={this.onRangeChange}
            />
            <div className="bvs-slider-selector__range-track" style={{ width: `${this.getTrackPercentage()}%` }} />
          </div>
        )}
      </div>
    );
  }
}

SliderSelector.propTypes = {
  selections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  className: PropTypes.string,
  primary: PropTypes.bool,
  triggerWhileScrolling: PropTypes.bool,
  big: PropTypes.bool,
  listWrapRef: PropTypes.instanceOf(Object),
  isWiderItem: PropTypes.bool,
};

SliderSelector.defaultProps = {
  className: '',
  selectedId: 0,
  primary: false,
  triggerWhileScrolling: true,
  big: false,
  listWrapRef: null,
  isWiderItem: false,
  name: null,
};

export default SliderSelector;
