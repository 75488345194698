import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const RenderInNode = ({ children, node }) => createPortal(children, node);

RenderInNode.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  node: PropTypes.instanceOf(Element).isRequired,
};

export default RenderInNode;
