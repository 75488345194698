import { useState, useEffect } from 'react';
import { compose, throttle } from 'underscore';
import { isSmall, isLargeOrGreater } from 'bv-helpers/screen';

const getSize = () => ({
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,
  outerHeight: window.outerHeight,
  outerWidth: window.outerWidth,
  isSmall: isSmall(),
  isLargeOrGreater: isLargeOrGreater(),
});

export default () => {
  const [windowSize, setWindowSize] = useState(getSize());

  useEffect(() => {
    const handleResize = throttle(compose(setWindowSize, getSize), 500, { leading: false });

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};
