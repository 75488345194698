import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import classnames from 'classnames';
import { currencyPrefix } from 'bv';

const withDecimal = (value) => (value && !Number.isNaN(Number(value))
  ? (Math.round(Number(value) * 100) / 100).toFixed(2)
  : '');

const getErrorType = (value, validate) => {
  if (Number(value) < (validate?.min?.val || 0)) return 'min';
  if (validate?.max?.val && Number(value) > validate.max.val) return 'max';
  return '';
};

const CurrencyInput = ({
  onAmountChange, changeAmount, validate, placeholder,
}) => {
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');

  const onChange = (e) => {
    const val = e.target.value.replaceAll(',', '.');
    if (!val) {
      setAmount('');
      setError(false);
      onAmountChange({ amount: 0, ...(validate ? { error: false } : {}) });
    } else if (
      /^(0|[1-9]+\d*|[1-9]+\d*\.\d{0,2}|0?\.\d{0,2})$/.test(val)
      && (!validate?.max?.val || withDecimal(val).length <= withDecimal(validate.max.val).length)
    ) {
      setAmount(val);
      const newAmount = val ? Number(withDecimal(val)) : 0;
      if (validate) {
        const errorType = getErrorType(val, validate);
        setError(errorType);
        onAmountChange({
          amount: newAmount,
          error: val ? !!errorType : false,
        });
      } else {
        onAmountChange({ amount: newAmount });
      }
    }
  };

  useEffect(() => {
    if (changeAmount || changeAmount === 0) {
      if (changeAmount < 0) {
        setError('min');
      } else {
        const newAmount = validate?.max?.val && changeAmount > validate.max.val
          ? validate.max.val
          : changeAmount;
        onChange({ target: { value: withDecimal(newAmount) } });
      }
    }
  }, [changeAmount]);

  return (
    <div className="currency-input">
      <div className="currency-input__input-wrapper">
        <input
          type="text"
          className={classnames('currency-input__input', { error, currency: !!amount })}
          placeholder={placeholder}
          autoComplete="off"
          onChange={onChange}
          onBlur={() => setAmount(withDecimal)}
          value={amount}
        />
        {amount && (
          <span className={classnames('currency-input__currency-prefix', { error })}>
            {currencyPrefix()}
          </span>
        )}
      </div>
      {validate && (
        <div
          className={classnames('currency-input__message', { error })}
        >
          {error ? (validate[error]?.msg || '') : (validate.defaultMsg || '')}
        </div>
      )}
    </div>
  );
};

CurrencyInput.propTypes = {
  onAmountChange: PropTypes.func.isRequired,
  changeAmount: PropTypes.number,
  validate: PropTypes.instanceOf(Object),
  placeholder: PropTypes.string,
};

CurrencyInput.defaultProps = {
  changeAmount: null,
  validate: false,
  placeholder: null,
};

export default CurrencyInput;
