import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const HeadPortal = ({ children }) => createPortal(children, document.head);

const Meta = ({ name, content }) => (
  <HeadPortal>
    <meta name={name} content={content} />
  </HeadPortal>
);

Meta.propTypes = {
  name: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default Meta;
