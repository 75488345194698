import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { isInComponentsIframe } from 'bv-services';
import classNames from 'classnames';
import { linkLocalizer } from 'bv-helpers/locale';
import { isURLAbsolute } from 'bv-helpers';

import Ambassador from 'BVComponents/ambassador';
import PartnerLogos from './partner_logos';
import FooterLogos from './footer_logos';
import TrustPilot from './trust_pilot';

const SiteFooterView = ({
  className,
  tagName: Tag,
  isFooterContentVisible,
  footerSettings,
}) => {
  const {
    footerLinks,
    snippet,
    footerLogos,
    partnerLogos,
    showLogo,
    showPartners,
    numberLogosFirstRowMobile,
    ambassador,
    trustPilotConfig,
  } = footerSettings;

  const footerInfoRef = useRef(null);
  const linkTargetAttrs = isInComponentsIframe() ? { target: '_blank', 'data-react': true } : {};

  useEffect(() => {
    if (footerInfoRef && footerInfoRef.current) {
      [...footerInfoRef.current.getElementsByTagName('a')].forEach((link) => {
        Object.entries(linkTargetAttrs).forEach(([key, value]) => link.setAttribute(key, value));
      });
    }
  }, [footerInfoRef]);

  const footerLinkUrl = (url) => (isURLAbsolute(url) ? url : linkLocalizer(url));

  return (
    <Tag className={`site-footer${className ? ` ${className}` : ''}`}>
      {showLogo && <div className={classNames('bvs-site-logo__footer', className)} />}
      {ambassador && <Ambassador hasBackground={false} ambassador={ambassador} />}
      {showPartners && partnerLogos.length > 0 && (
        <PartnerLogos
          partnerLogos={partnerLogos}
          numberLogosFirstRowMobile={numberLogosFirstRowMobile}
        />
      )}
      {isFooterContentVisible && (
        <div className="site-footer__content">
          {footerLinks && footerLinks.length > 0 && (
            <div className="site-footer__content-links">
              {footerLinks.map(({ url, title, openInNewTab }) => (
                <a
                  href={footerLinkUrl(url)}
                  target={openInNewTab ? '_blank' : '_self'}
                  className="static-page"
                  {...linkTargetAttrs}
                  key={title}
                  rel="noreferrer"
                >
                  {title}
                </a>
              ))}
            </div>
          )}
          <div
            className="site-footer__content-info"
            dangerouslySetInnerHTML={{ __html: sanitize(snippet) }}
            ref={footerInfoRef}
          />
          {trustPilotConfig.enabled
            && (
            <TrustPilot
              isLandingPage={className === 'lp'}
              {...trustPilotConfig}
            />
            )}
          <FooterLogos
            logos={footerLogos}
            footerLinkUrl={footerLinkUrl}
            linkTargetAttrs={linkTargetAttrs}
          />
        </div>
      )}
    </Tag>
  );
};

SiteFooterView.propTypes = {
  footerSettings: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
  tagName: PropTypes.string,
  isFooterContentVisible: PropTypes.bool,
};

SiteFooterView.defaultProps = {
  className: '',
  tagName: 'footer',
  isFooterContentVisible: true,
};

export default SiteFooterView;
