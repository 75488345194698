import { useState, useEffect } from 'react';

const initialState = {
  loading: true,
};

export default (fetchFn, refreshProps = []) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    fetchFn().then((json) => {
      setState({ loading: false, data: json });
    });

    return () => { setState(initialState); };
  }, refreshProps);

  return [state.data, state.loading];
};
