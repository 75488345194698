import PropTypes from 'prop-types';
import classnames from 'classnames';

export const SpinnerInner = ({ big }) => (
  <div className={`bvs-spinner${big ? '-big' : ''} loading`} />
);

SpinnerInner.propTypes = {
  big: PropTypes.bool.isRequired,
};

const Spinner = ({ big, wrapped }) => {
  if (!wrapped) return <SpinnerInner big={big} />;

  return (
    <div className={classnames('bvs-spinner-wrapper', {
      'is-big': big,
    })}
    >
      <SpinnerInner big={big} />
    </div>
  );
};

Spinner.propTypes = {
  big: PropTypes.bool,
  wrapped: PropTypes.bool,
};

Spinner.defaultProps = {
  big: true,
  wrapped: true,
};

export default Spinner;
