import PropTypes from 'prop-types';

const Heading = ({
  children, weight, tag, ...rest
}) => {
  const CustomTag = tag || `h${weight}`;
  return (
    <CustomTag className={`bvs-h${weight}`} {...rest}>
      {children}
    </CustomTag>
  );
};

Heading.propTypes = {
  tag: PropTypes.string,
  weight: PropTypes.number,
  children: PropTypes.node.isRequired,
};

Heading.defaultProps = {
  weight: 1,
  tag: null,
};

export default Heading;
