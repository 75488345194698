import { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from './modal';
import RenderInBody from './render_in_body';
import { useLockBodyScroll } from '../hooks';

const itemClassName = (activeId, itemId) => classnames('list-modal-item', {
  active: activeId === itemId,
});

const ListModal = ({
  onCloseClick,
  items,
  activeElement,
  idAttribute,
  descriptionAttribute,
  onItemClick,
  children,
  styleClasses,
}) => {
  const scrollableRef = useRef();

  useLockBodyScroll(scrollableRef);

  return (
    <RenderInBody>
      <Modal
        info
        iconDotsClosable
        icon={false}
        className={`list-modal ${styleClasses}`}
        onCloseClick={onCloseClick}
      >
        <ul className="list-modal-items" ref={scrollableRef}>
          {children || items.map((item) => (
            <li
              key={item[idAttribute]}
              className={itemClassName(activeElement[idAttribute], item[idAttribute])}
              onClick={() => onItemClick(item)}
            >
              {item[descriptionAttribute]}
            </li>
          ))}
        </ul>
      </Modal>
    </RenderInBody>
  );
};

ListModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Array),
  activeElement: PropTypes.instanceOf(Object),
  idAttribute: PropTypes.string,
  descriptionAttribute: PropTypes.string,
  onItemClick: PropTypes.func,
  children: PropTypes.node,
  styleClasses: PropTypes.string,
};

ListModal.defaultProps = {
  children: null,
  items: [],
  activeElement: {},
  idAttribute: '',
  descriptionAttribute: '',
  onItemClick: () => {},
  styleClasses: '',
};

export default ListModal;
