import PropTypes from 'prop-types';
import classnames from 'classnames';
import { cmsAssetPath } from 'bv';

const { BvApi } = window;

const Ambassador = ({ hasBackground, showImage, ambassador }) => {
  if (!ambassador) return null;

  // =============================================================
  // Image to be shown only if showImage is true
  // Gradient background to be added only if hasBackground is true
  // Text always shown
  // =============================================================

  return (
    <div
      className={classnames('ambassador-container', {
        'ambassador-container--with-bg': hasBackground,
      })}
    >
      {showImage && (
        <div className="ambassador-container__img-wrapper">
          <img
            className="ambassador-container__img"
            src={cmsAssetPath(ambassador.image)}
            alt={ambassador.name}
          />
        </div>
      )}
      <div className="ambassador-container__inner">
        <div className="ambassador-container__title">{BvApi.t('ambassador')}</div>
        <div className="ambassador-container__name">{ambassador.name}</div>
      </div>
    </div>
  );
};

Ambassador.propTypes = {
  showImage: PropTypes.bool,
  hasBackground: PropTypes.bool,
  ambassador: PropTypes.instanceOf(Object),
};

Ambassador.defaultProps = {
  showImage: true,
  hasBackground: true,
  ambassador: null,
};

export default Ambassador;
