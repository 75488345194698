import ModalWindow from 'BVComponents/modal_window';
import { t } from 'bv-i18n';

export default (tKey, modalProps = {}) => {
  const WithModalWindow = (WrappedComponent) => (props) => (
    <ModalWindow title={t(tKey)} {...modalProps}>
      <WrappedComponent {...props} />
    </ModalWindow>
  );

  WithModalWindow.displayName = 'withModalWindow';

  return WithModalWindow;
};
