import PropTypes from 'prop-types';
import Tab from '../tab';
import Carousel from '../carousel';

const SecondaryTabsMenuView = ({ tabs }) => (
  <Carousel className="site-secondary-nav-wrapper" scrollStep={234} displayFixedArrows>
    {tabs.map((tab) => <Tab tagName="span" key={tab.id} {...tab} />)}
  </Carousel>
);

SecondaryTabsMenuView.propTypes = {
  tabs: PropTypes.instanceOf(Array).isRequired,
};

export default SecondaryTabsMenuView;
