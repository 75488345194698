import { useEffect } from 'react';
import { registerHandler, unregisterHandler } from 'event-bus';

const useEventBus = (busId, updateEvents, dependencies = [], opts = { shouldRegister: true }) => {
  useEffect(() => {
    if (opts.shouldRegister) registerHandler(busId, updateEvents);

    return () => { if (opts.shouldRegister) unregisterHandler(busId, updateEvents); };
  }, dependencies);
};

export default useEventBus;
