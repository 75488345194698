import { useEffect, memo } from 'react';
import { defer } from 'underscore';
import { scrollableElement } from 'bv-helpers';

export default (
  getScrollContainer = scrollableElement,
) => {
  const WithScrollToTop = (WrappedComponent) => memo((props) => {
    useEffect(() => {
      defer(() => {
        const container = getScrollContainer();
        if (container) container.scrollTop = 0;
      });
    }, []);

    return <WrappedComponent {...props} />;
  });

  WithScrollToTop.displayName = 'withScrollToTop';

  return WithScrollToTop;
};
