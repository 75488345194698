import PropTypes from 'prop-types';
import classnames from 'classnames';

const modifiers = ['small', 'big', 'add', 'remove'];

const ContentIcon = ({ onClick, ...rest }) => {
  const className = classnames(
    'content-icon bvs-link',
    modifiers.reduce((accum, key) => ({
      ...accum,
      [`content-icon__${key}`]: rest[key],
    }), {}),
  );

  return <span className={className} onClick={onClick} />;
};

ContentIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ContentIcon;
