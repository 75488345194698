import { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { handleCta } from 'bv-services';
import { routing as routingSelectors } from 'redux-selectors';

import TabView from './tab_view';

const { makeIsCurrentPath } = routingSelectors;

const TabContainer = ({
  className,
  cta,
  selected,
  activeRef = null,
  ...rest
}) => {
  const onTabClicked = useCallback(() => {
    if (cta) {
      handleCta(cta);
    }
  });

  return (
    <TabView
      className={classnames('section', className, { selected })}
      cta={cta}
      onClick={onTabClicked}
      activeRef={selected ? activeRef : null}
      {...rest}
    />
  );
};

TabContainer.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  className: PropTypes.string,
  cta: PropTypes.instanceOf(Object),
  selected: PropTypes.bool.isRequired,
  activeRef: PropTypes.func,
};

TabContainer.defaultProps = {
  cta: null,
  href: null,
  activeRef: null,
  className: null,
};

const makeMapStateToProps = () => {
  const isCurrentPath = makeIsCurrentPath();

  return (state, props) => ({
    selected: props.href && isCurrentPath(state, props.href),
  });
};

export default connect(makeMapStateToProps)(TabContainer);
