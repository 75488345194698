import { Provider } from 'react-redux';
import SiteFooterContainer from './site_footer_container';
import reducer from '../duck';

const { store, addReducers } = window.reduxState;

addReducers({
  siteFooter: reducer,
});

const App = (props) => (
  <Provider store={store}>
    <SiteFooterContainer {...props} />
  </Provider>
);

export default App;
