import PropTypes from 'prop-types';
import classnames from 'classnames';

import getClassNameFromType from './icon/helpers/get_class_name_from_type';

const DotsIcon = ({
  horizontal,
  className: propClassName,
  type,
  onDotsClick,
}) => {
  const dotsClassNames = classnames('bvs-icon bvs-link', {
    'is-dots-vertical': !horizontal,
    'is-dots-horizontal': horizontal,
  }, propClassName, getClassNameFromType(type));

  return (
    <div className={dotsClassNames} onClick={onDotsClick}>
      <span />
      <span />
      <span />
    </div>
  );
};

DotsIcon.defaultProps = {
  horizontal: false,
  className: '',
  type: '',
  onDotsClick: () => {},
};

DotsIcon.propTypes = {
  horizontal: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  onDotsClick: PropTypes.func,
};

export default DotsIcon;
