import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SeoTitleView from './seo_title_view';

const SeoTitleContainer = ({
  seoHeading,
  defaultHeading,
  defaultHeadingPromise,
  ...rest
}) => {
  const [promiseHeading, setPromiseHeading] = useState();
  const heading = seoHeading || defaultHeading;

  useEffect(() => {
    if (!heading) {
      defaultHeadingPromise().then(setPromiseHeading);
    }
  }, [heading, defaultHeadingPromise]);

  return <SeoTitleView {...rest} heading={heading || promiseHeading} />;
};

SeoTitleContainer.propTypes = {
  seoHeading: PropTypes.string,
  defaultHeading: PropTypes.string,
  defaultHeadingPromise: PropTypes.func,
};

SeoTitleContainer.defaultProps = {
  seoHeading: '',
  defaultHeading: '',
  defaultHeadingPromise: () => Promise.resolve(''),
};

export default SeoTitleContainer;
