import { useEffect } from 'react';

export default (refreshProps = [], obj = window) => {
  useEffect(() => {
    if (obj?.scrollTo) {
      obj.scrollTo(0, 0);
    } else if (obj) {
      // eslint-disable-next-line no-param-reassign
      obj.scrollTop = 0;
    }
  }, refreshProps);
};
