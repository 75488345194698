import { useSelector } from 'react-redux';
import { property } from 'underscore';
import { useLoggedIn } from 'src/globals/hooks';
import SeoTitleContainer from './seo_title_container';

const SeoTitleRedux = ({ ...rest }) => {
  const loggedIn = useLoggedIn();
  const { fetching, heading } = useSelector(property('seo')) || {};

  if (loggedIn) return <SeoTitleContainer {...rest} />;

  if (fetching) return null;
  return <SeoTitleContainer {...rest} seoHeading={heading} />;
};

export default SeoTitleRedux;
