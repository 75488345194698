import PropTypes from 'prop-types';

import { range } from 'underscore';

const StarRating = ({ rating }) => (
  <ul className="racecard-rating">
    {range(5).map((number) => (
      <li className={(rating > number) ? 'checked' : ''} />
    ))}
  </ul>
);

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
};

export default StarRating;
