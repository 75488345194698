import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';

const absolutePathRegex = /^(https?:\/\/)/;

const Link = ({ children, to, ...rest }) => (absolutePathRegex.test(to)
  ? <a {...rest} href={to}>{children}</a>
  : <ReactRouterLink {...rest} to={to}>{children}</ReactRouterLink>);

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
};

Link.defaultProps = {
  children: null,
};

export default Link;
