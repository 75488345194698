import { useContext } from 'react';
import PropTypes from 'prop-types';
import { assetPath } from 'bv';
import Image from './image';
import { ScrollableContext } from '../contexts';

const Silk = ({ className, silk, trap }) => {
  const scrollableRef = useContext(ScrollableContext);

  return (
    <Image
      className={className}
      src={silk ? assetPath(`/silks/${silk}`) : trap}
      width="67"
      height="67"
      fallbackSrc={window.missingSilkImageUrl}
      scrollableRef={scrollableRef}
    />
  );
};

Silk.propTypes = {
  className: PropTypes.string,
  silk: PropTypes.string.isRequired,
  trap: PropTypes.string,
};

Silk.defaultProps = {
  className: '',
  trap: null,
};

export default Silk;
