import { useState } from 'react';
import { t } from 'bv-i18n';
import Modal from './modal';

const ErrorBoundaryFallback = () => {
  const [visible, setVisible] = useState(true);

  if (!visible) return null;

  const hide = () => { setVisible(false); };

  return (
    <Modal
      onCloseClick={hide}
      danger
      iconClosable
    >
      {t('errors_something_wrong')}
    </Modal>
  );
};

export default ErrorBoundaryFallback;
