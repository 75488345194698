import Native from './native';
import Hls from './hls';
import Iframe from './iframe';
import ExternalImg from './external_img';
import ShakaPlayer from './shaka_player';
import RMGPlayer from './rmg';

export default [
  Hls,
  Iframe,
  Native,
  ExternalImg,
  ShakaPlayer,
  RMGPlayer,
];
