import PropTypes from 'prop-types';
import Icon from './icon';
import ButtonCard from './button_card';

const ButtonCardWithArrow = ({ open, ...props }) => (
  <ButtonCard {...props}>
    <Icon id={`arrow-${open ? 'up' : 'down'}`} />
  </ButtonCard>
);

ButtonCardWithArrow.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default ButtonCardWithArrow;
