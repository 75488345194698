import { PureComponent } from 'react';
import PropTypes from 'prop-types';

const IFRAME_EXTENSIONS = /\.(html|aspx)($|\?)/i;

class Iframe extends PureComponent {
  static typeName = 'Iframe';

  static canPlay = (url) => IFRAME_EXTENSIONS.test(url);

  render() {
    const { url, opts } = this.props;

    return (
      <iframe src={url} {...opts} />
    );
  }
}

Iframe.propTypes = {
  url: PropTypes.string.isRequired,
  opts: PropTypes.instanceOf(Object).isRequired,
};

export default Iframe;
