/**
 * The Button Card component will be able to receive a type property.
 * This type will allow the Button to use a different className depending on the brand.
 * This will avoid having this mapping into the CSS, which causes lot of undesired duplication
 * Bigger CSS files due to the use of extend or having to create mapping css classes.
 *
 * The exported function will receive a type and will return the class to use depending on the brand
 * Without this, all brands would use the same className, having to create new CSS classes
 */

import { v as bvVar } from 'bv';

const mappings = {
  'sportbook-section-tabs': {
    default: 'bvs-button-tab',
  },
  'personal-details-footer-button': {
    default: '',
    16: 'bvs-button is-primary',
    20: 'bvs-button is-primary',
  },
};

export default (type) => mappings[type] && (
  mappings[type][bvVar('brandId')] || mappings[type].default
);
