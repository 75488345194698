import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { omit } from 'underscore';

import getClassNameFromType from './button_card/helpers/get_class_name_from_type';

const ButtonCard = forwardRef((props, ref) => {
  const className = classnames(props.className, {
    active: props.active,
    'is-small': props.small,
    'is-large': props.large,
    multiline: props.multiline,
    'bvs-button-card': !props.noClass,
  }, getClassNameFromType(props.type));

  const elementProps = {
    ...omit(props, 'dataReact', 'multiline', 'noClass'),
    className,
    ref,
    ...(props.dataReact ? {
      'data-react': true,
    } : {}),
  };

  const getButtonElement = (href, to) => {
    let element;
    if (href) {
      element = 'a';
    } else if (to) {
      element = Link;
    } else {
      element = 'button';
    }
    return element;
  };

  const Element = getButtonElement(props.href, props.to);

  return (
    <Element
      {...elementProps}
    >
      {props.label}
      {props.children}
    </Element>
  );
});

ButtonCard.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.string,
  children: PropTypes.node,
  multiline: PropTypes.bool,
  noClass: PropTypes.bool,
  type: PropTypes.string,
  small: PropTypes.string,
  large: PropTypes.string,
  dataReact: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.string,
};

ButtonCard.defaultProps = {
  onClick: () => {},
  to: null,
  className: '',
  active: null,
  children: null,
  multiline: false,
  noClass: false,
  type: null,
  small: null,
  large: null,
  dataReact: false,
  label: null,
  href: null,
  target: null,
};

export default ButtonCard;
