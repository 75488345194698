import PropTypes from 'prop-types';
import Meta from './meta';

const Robots = ({ content }) => (
  <Meta name="robots" content={content} />
);

Robots.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Robots;
