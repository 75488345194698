const docElement = document.documentElement;
const { scrollTo } = window;

const contentOffsetTop = ({ contentElRef, fixedNav }) => {
  const contentBoundingTop = contentElRef.current.getBoundingClientRect().top;
  const docBoundingTop = docElement.getBoundingClientRect().top;
  const headerElement = document.getElementById('header');
  const headerHeight = headerElement.offsetHeight;
  // When not using position fixed the nav will add a negative margin
  // When using position fixed, calculating the rect is not substracting it
  // So we have to substract it
  const notCountedNavMargin = fixedNav ? 50 : 0;

  return contentBoundingTop - docBoundingTop - headerHeight - notCountedNavMargin;
};

export const scrollToContentTop = (scrollingValues) => {
  scrollTo(
    0,
    contentOffsetTop(scrollingValues),
  );
};

export const contentScrolled = (scrollingValues) => (
  window.scrollY > contentOffsetTop(scrollingValues)
);
