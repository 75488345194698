import PropTypes from 'prop-types';

const SeoTitleView = ({ tag, heading, setHeaderRef }) => {
  const Tag = tag;

  return (
    <div id="site-header-title" className="site-header-title" ref={setHeaderRef}>
      <Tag className="bvs-header bvs-ellipsis bvs-h4">
        {heading}
      </Tag>
    </div>
  );
};

SeoTitleView.propTypes = {
  tag: PropTypes.string,
  heading: PropTypes.string.isRequired,
  setHeaderRef: PropTypes.func,
};

SeoTitleView.defaultProps = {
  setHeaderRef: null,
  tag: 'h1',
};

export default SeoTitleView;
