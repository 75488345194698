import { guard } from 'bv-services/features';
import { useState, useEffect } from 'react';

export default (featureName) => {
  const [featureState, setFeatureState] = useState({
    feature: featureName,
    state: [false, false],
  });

  useEffect(() => {
    guard(featureName)
      .then((enabled) => {
        setFeatureState({
          feature: featureName,
          state: [enabled, true],
        });
      });
  }, [featureName]);

  // We need this check due to React feature reconciliation (which is great!)
  // The hook is meant to keep the state of 1 feature, we need to check if the current state
  // belongs to the current feature
  return featureState.feature === featureName
    ? featureState.state
    : [false, false];
};
