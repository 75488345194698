import { useEffect } from 'react';

const elem = document.documentElement;

const fullscreenSupported = elem.requestFullscreen || elem.webkitRequestFullscreen;

const toggleFullscreen = () => {
  if (document.fullscreenElement) {
    document.exitFullscreen();
  } else if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  }
};

const fullscreenOff = () => {
  if (document.fullscreenElement || document.webkitFullscreenElement) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }
};

export default () => {
  useEffect(() => () => fullscreenOff(), []);

  return [toggleFullscreen, fullscreenSupported];
};
