import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { requireScript } from 'bv';
import { t } from 'bv-i18n';
import MessageBox from '../../message_box';
import { configureDRM } from '../helpers/drm';

const ErrorMessage = () => (
  <div className="media-streaming-player media-streaming--message">
    <MessageBox title={t('javascript.inplay_streaming.error.generic')} warning />
  </div>
);

const ShakaPlayer = ({
  customPlayerConfiguration, format, opts, url,
}) => {
  const [error, setError] = useState(false);
  const videoRef = useRef();

  useEffect(() => {
    let player;

    requireScript(window.JSBundles.shakaPlayer).then(() => {
      const { shaka } = window;

      shaka.polyfill.installAll();

      if (shaka.Player.isBrowserSupported()) {
        player = new shaka.Player(videoRef.current);
        configureDRM({ ...customPlayerConfiguration, format, player });

        player.load(url)
          .catch(() => setError(true));
      } else {
        setError(true);
      }
    });

    return () => {
      player?.destroy();
    };
  }, []);

  if (error) {
    return <ErrorMessage />;
  }

  return <video ref={videoRef} {...opts} />;
};

ShakaPlayer.propTypes = {
  customPlayerConfiguration: PropTypes.instanceOf(Object).isRequired,
  url: PropTypes.string.isRequired,
  opts: PropTypes.instanceOf(Object).isRequired,
  format: PropTypes.string.isRequired,
};

ShakaPlayer.typeName = 'ShakaPlayer';

// don't let the auto-select pick this one, only should be used with
// explicit player-type prop passed in for the <VideoPlayer> component
ShakaPlayer.canPlay = () => false;

export default ShakaPlayer;
