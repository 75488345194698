import { useEffect, useState } from 'react';
import { throttle } from 'underscore';

export default ({ scrollableRef, onEndReach }) => {
  if (!scrollableRef) return;

  // This state is to prevent onEndReach callback from unecessary firing
  const [endReachedState, setEndReachedState] = useState(false);

  const checkIfEndReached = () => {
    const target = scrollableRef?.current;
    if (target) return target.firstChild.clientHeight - target.scrollTop <= target.clientHeight;
    return false;
  };

  const onEndThrottled = throttle(() => {
    const endReached = checkIfEndReached();
    if (endReached && !endReachedState) {
      setEndReachedState(true);
      onEndReach(true);
    } else if (!endReached && endReachedState) {
      setEndReachedState(false);
      onEndReach(false);
    }
  }, 400);

  useEffect(() => {
    scrollableRef?.current?.addEventListener('scroll', onEndThrottled);

    return () => {
      scrollableRef?.current?.removeEventListener('scroll', onEndThrottled);
    };
  }, [endReachedState]);
};
