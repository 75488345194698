import { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Native extends PureComponent {
  static typeName = 'Native';

  static canPlay = () => true;

  render() {
    const { url, opts } = this.props;

    return (
      <video src={url} {...opts} />
    );
  }
}

Native.propTypes = {
  url: PropTypes.string.isRequired,
  opts: PropTypes.instanceOf(Object).isRequired,
};

export default Native;
