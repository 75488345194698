import { useEffect, useRef } from 'react';
import { v as bvVar } from 'bv';
import PropTypes from 'prop-types';

const RMGPlayer = ({ customPlayerConfiguration }) => {
  const ref = useRef();

  useEffect(() => {
    const { CSSBundles } = window;
    const script = document.createElement('script');
    script.src = bvVar('rmgPlayerUrl');
    script.setAttribute('data-uuid', customPlayerConfiguration.fixtureUuid);
    script.setAttribute('data-outletkey', customPlayerConfiguration.outletAuthKey);
    script.setAttribute('data-apptype', 'csb');
    script.setAttribute('data-env', 'betting');
    script.setAttribute('data-streamuuid', customPlayerConfiguration.livestreamUuid);
    script.setAttribute('data-streamuser', customPlayerConfiguration.userId);
    script.setAttribute('data-oauthtoken', customPlayerConfiguration.accessToken);
    script.setAttribute('data-rmg', 'true');
    script.setAttribute('data-width', '100%');
    script.setAttribute('data-flyaway', true);
    script.setAttribute('data-flyaway-top', '88px');
    script.setAttribute('data-flyaway-left', '16px');
    script.setAttribute('data-flyaway-draggable', true);
    script.async = true;

    if (CSSBundles.rmgPlayer) {
      script.setAttribute('data-cssdiff', CSSBundles.rmgPlayer);
    }

    ref.current.appendChild(script);
  }, []);

  return <div className="rmg-player-container" ref={ref} />;
};

RMGPlayer.propTypes = {
  customPlayerConfiguration: PropTypes.instanceOf(Object).isRequired,
};

RMGPlayer.typeName = 'RMGPlayer';

// don't let the auto-select pick this one, only should be used with
// explicit player-type prop passed in for the <VideoPlayer> component
RMGPlayer.canPlay = () => false;

export default RMGPlayer;
