import PropTypes from 'prop-types';

const Paragraph = ({ children }) => (
  <p className="bvs-p">{children}</p>
);

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Paragraph;
