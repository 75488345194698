const appendToSegmentURI = (suffix) => (type, request) => {
  if (type === window.shaka.net.NetworkingEngine.RequestType.SEGMENT) {
    request.uris = request.uris.map((uri) => `${uri}${uri.includes('?') ? '&' : '?'}${suffix}`);
  }
};

const getRequestFilters = ({ format, token }) => ({
  DASH: [
    appendToSegmentURI(`${token}`),
  ],
  HLS: [
    window.shaka.util.FairPlayUtils.ezdrmFairPlayRequest,
  ],
})[format] || [];

const getResponseFilters = ({ format }) => ({
  HLS: [
    window.shaka.util.FairPlayUtils.commonFairPlayResponse,
  ],
})[format] || [];

const getDRMConfiguration = ({
  fairplay, fairplayCertificate, playready, widevine,
}) => ({
  drm: {
    servers: {
      'com.apple.fps': fairplay,
      'com.microsoft.playready': playready,
      'com.widevine.alpha': widevine,
    },
    advanced: {
      'com.apple.fps': {
        serverCertificateUri: fairplayCertificate,
      },
    },
  },
});

export const configureDRM = ({
  drm, format, player, token,
}) => {
  if (drm) {
    player.configure(getDRMConfiguration(drm));

    getRequestFilters({ format, token })
      .forEach((filter) => player.getNetworkingEngine().registerRequestFilter(filter));
    getResponseFilters({ format })
      .forEach((filter) => player.getNetworkingEngine().registerResponseFilter(filter));
  }
};
