import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import Modal from './modal';
import RenderInBody from './render_in_body';

const ConfirmModal = ({
  handleNoBtnClick, handleYesBtnClick, title, btnNoInversed,
}) => (
  <RenderInBody>
    <Modal
      info
      icon
      title={title}
      actions={[
        {
          secondary: true,
          inverse: true,
          label: t('yes'),
          onClick: handleYesBtnClick,
        },
        {
          secondary: true,
          inverse: btnNoInversed,
          label: t('no'),
          onClick: handleNoBtnClick,
        },
      ]}
    />
  </RenderInBody>
);

ConfirmModal.propTypes = {
  handleNoBtnClick: PropTypes.func.isRequired,
  handleYesBtnClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  btnNoInversed: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  btnNoInversed: true,
};

export default ConfirmModal;
