import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useBodyClass } from '../hooks';

import Icon from './icon';
import RenderInBody from './render_in_body';

const RenderInFullscreen = ({
  title,
  className,
  onCloseClick,
  children,
}) => {
  useBodyClass('has-modal');

  return (
    <RenderInBody>
      <div className={classnames('modal_wrapper', { [className]: className })}>
        <div className="modal_header">
          <div className="modal_header_info_wrapper" />
          <div className="modal_title_wrapper">
            <span className="modal_title">{ title }</span>
          </div>
          <span className="close">
            <span className="close-icon" onClick={onCloseClick}>
              <Icon id="close" type="modal-header-close-icon" big />
            </span>
          </span>
        </div>

        <div className="modal_contents_wrapper scrollable has-scroll-bars">
          <div className="modal_contents">
            { children }
          </div>
        </div>
      </div>
    </RenderInBody>
  );
};

RenderInFullscreen.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
};

RenderInFullscreen.defaultProps = {
  className: null,
};

export default RenderInFullscreen;
