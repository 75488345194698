import { useEffect } from 'react';
import { enable, disable } from 'bv-services/body-scroll-manager';

export default (scrollableRef) => {
  useEffect(() => {
    const { current } = scrollableRef;
    disable(current);
    return () => {
      enable(current);
    };
  }, []);
};
