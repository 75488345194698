import { PureComponent } from 'react';

export default (WrappedComponent, initValue = () => true) => {
  class WithFold extends PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        folded: initValue(props),
      };

      this.toggleFolded = this.toggleFolded.bind(this);
    }

    toggleFolded() {
      this.setState(({ folded }) => ({
        folded: !folded,
      }));
    }

    render() {
      const { folded } = this.state;

      return (
        <WrappedComponent
          folded={folded}
          toggleFolded={this.toggleFolded}
          {...this.props}
        />
      );
    }
  }

  WithFold.displayName = 'withFold';

  return WithFold;
};
