import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { requireScript } from 'bv';

const { frontRowSeatSdk } = window.JSBundles;
// this is a hack so we can get data out of redux
// state after we re-authrozed a stream whent he video
// loaded, because IMG won't take the token until their
// iframe is on a separate view
const { store: { getState } } = window.reduxState;

class ExternalImg extends PureComponent {
  static typeName = 'ExternalImg';

  // don't let the auto-select pick this one, only should be used with
  // explicit player-type prop passed in for the <VideoPlayer> component
  static canPlay = () => false;

  render() {
    const { doFetchStream } = this.props;
    requireScript(frontRowSeatSdk).then(() => {
      const { frontRowSeat } = window;
      const { MessageTopics } = frontRowSeat.eventCentreUtils;
      const imgPlayerNode = document.getElementById('img-arena-event-centre');
      imgPlayerNode.eventCentreInstance.on(
        MessageTopics.CONTEXT_UPDATE,
        (event) => {
          if (event.view === 'course') {
            doFetchStream().then(() => {
              window.setTimeout(() => {
                const state = getState();
                const customPlayerConfiguration = state
                  .streaming
                  .urlData
                  .custom_player_configuration;

                if (!customPlayerConfiguration) return;
                imgPlayerNode.eventCentreInstance.emit(
                  MessageTopics.VIDEO_PLAYBACK_AUTH_RESPONSE,
                  {
                    ...customPlayerConfiguration,
                    timestamp: new Date(customPlayerConfiguration.timestamp).getTime(),
                  },
                );
              }, 250);
            });
          }
        },
      );
    });
    return null;
  }
}

ExternalImg.propTypes = {
  doFetchStream: PropTypes.func.isRequired,
};

export default ExternalImg;
