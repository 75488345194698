import { getJSON } from 'bv-fetch';
import useFetch from './use_fetch';

const sportTabComponent = (componentId, sportId, { useEventPathId }) => getJSON(
  `/bv_api/sportsbook_components/sports/${sportId}/sport_tab_components/${componentId}?use_event_path_id=${useEventPathId}`,
).catch(() => ({}));

export default (componentId, sportId, refreshProps = [], { useEventPathId } = {}) => (
  useFetch(() => sportTabComponent(componentId, sportId, { useEventPathId }), refreshProps)
);
