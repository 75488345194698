import PropTypes from 'prop-types';
import players from './players';

const getActivePlayer = (playerType, url) => (
  players.find((Player) => Player.typeName === playerType)
  || players.find((Player) => Player.canPlay(url))
);

const VideoPlayer = ({
  url, playerType, customPlayerConfiguration, opts, doFetchStream, format,
}) => {
  const Player = getActivePlayer(playerType, url);

  return (
    <Player
      url={url}
      opts={opts}
      customPlayerConfiguration={customPlayerConfiguration}
      doFetchStream={doFetchStream}
      format={format}
    />
  );
};

VideoPlayer.defaultProps = {
  playerType: null,
  customPlayerConfiguration: null,
  format: null,
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  opts: PropTypes.instanceOf(Object).isRequired,
  customPlayerConfiguration: PropTypes.instanceOf(Object),
  playerType: PropTypes.string,
  doFetchStream: PropTypes.func.isRequired,
  format: PropTypes.string,
};

export default VideoPlayer;
