import PropTypes from 'prop-types';
import classnames from 'classnames';

import Card from './card';

const ListLinks = ({ children, className }) => (
  <Card className={classnames('bvs-list-links', className)}>
    <ul>{children}</ul>
  </Card>
);

ListLinks.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ListLinks.defaultProps = {
  className: '',
};

export default ListLinks;
