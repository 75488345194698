/* eslint-disable compat/compat */
// disable compat/compat because this is a safe access to those apis

import { useState, useEffect } from 'react';

export default (element, rootMargin) => {
  const available = 'IntersectionObserver' in window
    && 'IntersectionObserverEntry' in window
    && 'isIntersecting' in window.IntersectionObserverEntry.prototype;
  const [isVisible, setState] = useState(!available);

  useEffect(() => {
    const observer = available && new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setState(entry.isIntersecting);
          observer.unobserve(element.current);
        }
      },
      {
        rootMargin,
      },
    );

    if (observer && element.current) observer.observe(element.current);

    return () => {
      if (observer && element.current) observer.unobserve(element.current);
    };
  }, []);

  return isVisible;
};
