import { cmsAssetPath } from 'bv';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PartnerLogos = ({
  partnerLogos,
  numberLogosFirstRowMobile,
}) => (
  <div className="partner-logos">
    {
      partnerLogos.map((partnerLogo, i) => {
        const Tag = partnerLogo.url ? 'a' : 'span';

        return (
          <Tag
            key={partnerLogo.image}
            className={classNames('partner-logos__logo', {
              'partner-logos__logo--order-1': numberLogosFirstRowMobile && numberLogosFirstRowMobile <= i,
            })}
            style={{ backgroundImage: `url(${cmsAssetPath(partnerLogo.image)})` }}
            aria-label="Partner logo"
            role="img"
            {... partnerLogo.url ? { href: partnerLogo.url, target: '_blank', rel: partnerLogo.rel || '' } : {}}
          />
        );
      })
    }
  </div>
);

PartnerLogos.propTypes = {
  numberLogosFirstRowMobile: PropTypes.number,
  partnerLogos: PropTypes.arrayOf(Object),
};

PartnerLogos.defaultProps = {
  numberLogosFirstRowMobile: 2,
  partnerLogos: [],
};

export default PartnerLogos;
