import { cmsAssetPath } from 'bv';
import PropTypes from 'prop-types';

const FooterLogos = ({ logos, footerLinkUrl, linkTargetAttrs }) => (
  <div className="site-footer__content-logos">
    {
      logos.map(({
        class: logoClassName, href, alt, icon, ...rest
      }) => {
        if (icon) {
          return (
            <a
              href={footerLinkUrl(href)}
              key={href}
              aria-label="Footer logo"
              {...rest}
              {...linkTargetAttrs}
            >
              <img
                src={cmsAssetPath(icon.url)}
                height={icon.height}
                width={icon.width}
                alt={alt}
              />
            </a>
          );
        }

        return (
          <a
            href={footerLinkUrl(href)}
            className={logoClassName}
            {...rest}
            {...linkTargetAttrs}
            aria-label="Footer logo"
            key={href}
          />
        );
      })
    }
  </div>
);

FooterLogos.propTypes = {
  logos: PropTypes.arrayOf(Object),
  footerLinkUrl: PropTypes.func.isRequired,
  linkTargetAttrs: PropTypes.instanceOf(Object),
};

FooterLogos.defaultProps = {
  logos: [],
  linkTargetAttrs: {},
};

export default FooterLogos;
