import PropTypes from 'prop-types';
import classnames from 'classnames';

const Card = ({
  children, header, className, data, id,
}) => (
  <div id={id} className={classnames(['bvs-card', className])} {...data}>
    { header && <h3 className="bvs-card-header">{ header }</h3>}
    { children }
  </div>
);

Card.propTypes = {
  header: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  data: PropTypes.instanceOf(Object),
};

Card.defaultProps = {
  header: '',
  id: undefined,
  className: '',
  data: {},
};

export default Card;
