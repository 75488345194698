import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

class RenderInBody extends PureComponent {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { className } = this.props;

    this.element = document.createElement('div');
    // Sometimes a generic div in the middle is not helpful
    this.element.className = className;
    this.container().appendChild(this.element);
  }

  componentWillUnmount() {
    this.container().removeChild(this.element);
  }

  container() {
    const { container } = this.props;

    return container || document.body;
  }

  render() {
    const { children } = this.props;

    return createPortal(
      children,
      this.element,
    );
  }
}

RenderInBody.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  container: PropTypes.instanceOf(Object),
  className: PropTypes.string,
};

RenderInBody.defaultProps = {
  container: undefined,
  className: '',
};

export default RenderInBody;
