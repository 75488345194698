import PropTypes from 'prop-types';

import Icon from '../icon';

const Arrow = ({ direction, disabled, onClick }) => (
  <div className={`bvs-carousel__arrow-wrap bvs-carousel__arrow-wrap--${direction}${disabled ? ' disabled' : ''}`}>
    <div className={`bvs-carousel__arrow bvs-carousel__arrow--${direction}${disabled ? ' disabled' : ''}`} onClick={onClick}>
      <Icon id={`arrow-${direction}`} main />
    </div>
  </div>
);

Arrow.propTypes = {
  direction: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Arrow;
