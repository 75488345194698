import PropTypes from 'prop-types';
import { Fragment } from 'react';
import SkeletonColumns from '../skeleton_column';

const CasinoLayout = ({ hasHeader }) => (
  <>
    {hasHeader && <div className="skeleton__header component-loading" />}
    {[...Array(3)].map((_, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={i}>
        {['casino-skeleton-game-images', 'casino-skeleton-game-titles'].map((type) => (
          <div className={`${type} component-loading`}>
            <div className="skeleton is-loading">
              <SkeletonColumns cols={8} />
            </div>
          </div>
        ))}
      </Fragment>
    ))}
  </>
);

CasinoLayout.propTypes = {
  hasHeader: PropTypes.bool.isRequired,
};

export default CasinoLayout;
