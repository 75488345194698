import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from './button';
import Icon from './icon';

const MessageBox = (props) => {
  const {
    className: classNameProp,
    onCloseClick,
    title,
    iconClosable,
    iconClosableType,
    iconDotsClosable,
    icon,
    children,
    actions,
    ...rest
  } = props;

  const {
    success,
    info,
    warning,
    danger,
  } = rest;

  const className = classnames('bvs-msg-box', classNameProp, {
    'is-success': success,
    'is-info': info,
    'is-warning': warning,
    'is-danger': danger,
  });

  return (
    <div className={className}>
      {iconClosable
        && (
          <div className="close-icon" onClick={onCloseClick}>
            <Icon
              id="close"
              type={iconClosableType}
              className={classnames('bvs-msg-box__close', {
                'is-active': !iconClosableType,
              })}
            />
          </div>
        )}
      {iconDotsClosable
        && (
          <div className="close-icon" onClick={onCloseClick}>
            <Icon id="dots-horizontal" type="list-modal-dots-close" className="bvs-msg-box__dots-close">
              <span />
              <span />
              <span />
            </Icon>
          </div>
        )}
      {icon
        && <Icon {...rest} type="msg-box-icon" />}
      {title
        && <h3 className="bvs-msg-box__title">{title}</h3>}
      <section>
        {children}
      </section>
      {actions.length > 0
        && (
        <div className="bvs-button-group">
          {actions.map((button) => <Button {...button} key={button.id || button.label} />)}
        </div>
        )}
    </div>
  );
};

MessageBox.propTypes = {
  actions: PropTypes.arrayOf(Object),
  className: PropTypes.string,
  title: PropTypes.string,
  iconClosable: PropTypes.bool,
  iconClosableType: PropTypes.string,
  iconDotsClosable: PropTypes.bool,
  success: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  children: PropTypes.node,
  onCloseClick: PropTypes.func,
  icon: PropTypes.bool,
};

MessageBox.defaultProps = {
  actions: [],
  className: '',
  title: '',
  iconClosable: false,
  iconClosableType: null,
  iconDotsClosable: false,
  success: false,
  info: false,
  warning: false,
  danger: false,
  children: null,
  onCloseClick: () => {},
  icon: true,
};

export default MessageBox;
