import PropTypes from 'prop-types';
import classnames from 'classnames';

import getClassNameFromType from './icon/helpers/get_class_name_from_type';

const Icon = ({
  success,
  info,
  warning,
  danger,
  id,
  big,
  active,
  alternative,
  inverted,
  main,
  className: propClassName,
  type,
  testId,
  onClick,
  children,
  hidden,
}) => {
  const className = classnames('bvs-icon', {
    'is-check': success,
    'is-info': info,
    'is-warning': warning,
    'is-danger': danger,
    [`is-${id}`]: id,
    'is-big': big,
    'is-active': active,
    'is-alternative': alternative,
    'is-inverted': inverted,
    'is-main': main,
    'is-hidden': hidden,
  }, propClassName, getClassNameFromType(type));

  return (
    <span data-testid={testId} className={className} onClick={onClick}>{children}</span>
  );
};

Icon.propTypes = {
  success: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  big: PropTypes.bool,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  alternative: PropTypes.bool,
  inverted: PropTypes.bool,
  main: PropTypes.bool,
  type: PropTypes.string,
  testId: PropTypes.string,
  children: PropTypes.node,
  hidden: PropTypes.bool,
};

Icon.defaultProps = {
  success: false,
  info: false,
  warning: false,
  danger: false,
  id: null,
  className: '',
  big: false,
  onClick: null,
  active: false,
  alternative: false,
  inverted: false,
  main: false,
  type: '',
  testId: null,
  children: null,
  hidden: false,
};

export default Icon;
