import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isSmall } from 'bv-helpers/screen';
import classnames from 'classnames';
import { isEmpty } from 'underscore';

import Overlay from './overlay';
import MessageBox from './message_box';

const Modal = (props) => {
  const {
    actionSheet,
    className: classNameProp,
    overlayFullscreen,
    modalClassName,
    onCloseClick,
    ignoreOverlayClick,
    dataLayer,
    danger,
    title,
    children,
  } = props;

  useEffect(() => {
    if (!isEmpty(dataLayer)) {
      const dataToPush = {
        ...dataLayer,
        event: dataLayer?.event ? dataLayer?.event : 'evt_dialog_unknown_type',
      };
      window.dataLayer.push(dataToPush);
    } else if (danger) {
      /* eslint no-underscore-dangle: ["error", { "allow": ["__html"] }] */
      const dataToPush = {
        error_title: title,
        error_message: children?.props?.dangerouslySetInnerHTML?.__html,
        error_key: children?.props?.dangerouslySetInnerHTML?.__html,
        event: 'evt_dialog_danger',
        error_code: 'E0x013',
      };
      window.dataLayer.push(dataToPush);
    }
  }, []);

  const className = classnames({
    'bvs-modal': true,
    'is-action-sheet': actionSheet,
    [classNameProp]: true,
  });

  const wrapperClassName = classnames({
    'bvs-modal-container': true,
    [modalClassName]: true,
    [`${classNameProp}-wrapper`]: !!classNameProp,
  });

  return (
    <div className={wrapperClassName}>
      <Overlay
        fullscreen={overlayFullscreen}
        {...((isSmall() && onCloseClick && !ignoreOverlayClick)
          ? { onClick: onCloseClick }
          : {}
        )}
      />
      <MessageBox {...props} className={className} />
    </div>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  actionSheet: PropTypes.bool,
  onCloseClick: PropTypes.func,
  overlayFullscreen: PropTypes.bool,
  modalClassName: PropTypes.string,
  ignoreOverlayClick: PropTypes.bool,
  dataLayer: PropTypes.instanceOf(Object),
  danger: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.instanceOf(Object),
};

Modal.defaultProps = {
  className: '',
  onCloseClick: undefined,
  actionSheet: false,
  overlayFullscreen: true,
  modalClassName: '',
  ignoreOverlayClick: false,
  dataLayer: {},
  danger: undefined,
  title: undefined,
  children: undefined,
};

export default Modal;
