import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default (className) => (WrappedComponent) => {
  class WithTouch extends PureComponent {
    constructor(props) {
      super(props);

      this.toggleActive = this.toggleActive.bind(this);

      this.activeClassName = classnames(
        className, props.className,
      );
      this.inactiveClassName = props.className;

      this.state = { active: false };
    }

    toggleActive() {
      const { active } = this.state;

      this.setState({ active: !active });
    }

    render() {
      const { active } = this.state;

      return (
        <WrappedComponent
          onTouchStart={this.toggleActive}
          onTouchEnd={this.toggleActive}
          {...this.props}
          className={active ? this.activeClassName : this.inactiveClassName}
        />
      );
    }
  }

  WithTouch.propTypes = {
    className: PropTypes.string,
  };

  WithTouch.defaultProps = {
    className: null,
  };

  WithTouch.displayName = 'withTouch';

  return WithTouch;
};
