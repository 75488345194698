// TODO: As spoken with Eze, this should be a component not related to coupons
// General expandable card not composed of ul and li but of divs
// Please, update me
import { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class List extends Component {
  constructor(props) {
    super(props);

    const { expanded } = this.props;

    this.state = { expanded };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { expandable } = this.props;
    const { expanded } = this.state;

    if (!expandable) {
      return;
    }

    this.setState({
      expanded: !expanded,
    });
  }

  render() {
    const {
      expandable, hasHeader, header, children,
    } = this.props;

    const { expanded } = this.state;

    const className = classnames('list-wrapper', {
      expandable,
      open: expanded,
    });

    const arrowIconClassName = classnames('bvs-icon', {
      'is-arrow-solid-circle-up': expanded,
      'is-arrow-solid-circle-down': !expanded,
    });

    return (
      <div className={className}>
        {hasHeader && (
          <div className="list-header bvs-link" onClick={this.toggle}>
            <h3>{header}</h3>
            {
              expandable && (
                <div className="list-header__icons">
                  <span className={arrowIconClassName} />
                </div>
              )
            }
          </div>
        )}
        { (!expandable || expanded) && (
          <ul className="bvs-card">
            { children }
          </ul>
        ) }
      </div>
    );
  }
}

List.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool,
  expandable: PropTypes.bool,
  hasHeader: PropTypes.bool,
};

List.defaultProps = {
  expanded: false,
  expandable: false,
  hasHeader: true,
};

export default List;
