import PropTypes from 'prop-types';
import classNames from 'classnames';
import SkeletonColumns from './skeleton_column';
import CasinoLayout from './layouts/casino_layout';

const Skeleton = (props) => {
  const {
    skeletonType,
    hasHeader,
    loading,
  } = props;

  const skeletonClass = classNames('skeleton', `skeleton--${skeletonType}`, {
    'is-loading': loading,
  });

  const skeletonLayout = () => {
    switch (skeletonType) {
      case 'home-casino-wrapper':
        return 6;
      case 'upcoming-races-wrapper':
      case 'trending-bets-wrapper':
      case 'home-live-casino-wrapper':
      case 'bet-boost-market':
      case 'priceitup-wrapper':
        return 4;
      case 'lucky-dip-wrapper':
        return 2;
      default:
        return 1;
    }
  };

  return skeletonType === 'casino-wrapper'
    ? (
      <CasinoLayout hasHeader={hasHeader} />
    ) : (
      <div className={`${skeletonType} component-loading`}>
        <div className={skeletonClass}>
          {hasHeader && <div className="skeleton__header" />}
          <SkeletonColumns cols={skeletonLayout(skeletonType)} />
        </div>
      </div>
    );
};

Skeleton.propTypes = {
  skeletonType: PropTypes.string,
  hasHeader: PropTypes.bool,
  loading: PropTypes.bool,
};

Skeleton.defaultProps = {
  skeletonType: 'default',
  hasHeader: true,
  loading: false,
};

export default Skeleton;
