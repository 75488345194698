import { useFetch, useLoggedIn } from '../../hooks';
import TabsMenuView from './tabs_menu_view';
import fetchSections from './api/fetchSections';

const TabsMenuContainer = () => {
  const loggedIn = useLoggedIn();
  const [data] = useFetch(fetchSections, [loggedIn]);

  return <TabsMenuView tabs={data} />;
};

export default TabsMenuContainer;
