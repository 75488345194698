import PropTypes from 'prop-types';
import classnames from 'classnames';

const Options = (props) => props.options.map((option) => {
  const { value, label } = typeof option === 'string'
    ? { value: option, label: option }
    : option;

  return (
    <option value={value} key={value}>
      {label}
    </option>
  );
});

Options.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
};

const Select = ({
  includeBlank,
  onChange,
  options,
  value,
  name,
  className,
  disabled,
  id,
}) => {
  const classNames = classnames('bvs-form-control bvs-link', className);

  return (
    <select
      className={classNames}
      onChange={onChange}
      name={name}
      id={id}
      disabled={disabled}
      {...(value ? { value } : { defaultValue: value })}
    >
      {includeBlank && <option value="">{includeBlank}</option>}
      {
        options instanceof Array
          ? <Options options={options} />
          : Object.entries(options).map(([group, groupOptions]) => (
            <optgroup key={group} label={group}>
              <Options options={groupOptions} />
            </optgroup>
          ))
      }
    </select>
  );
};

Select.propTypes = {
  includeBlank: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.object,
  ]).isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  includeBlank: '',
  className: null,
  disabled: false,
  value: '',
  id: null,
  name: null,
};

export default Select;
