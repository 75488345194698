import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ButtonTab = forwardRef((props, ref) => {
  const className = classnames(props.className, {
    active: props.active,
    multiline: props.multiline,
    disabled: props.disabled,
    'bvs-button-tab': !props.noClass,
  });

  const elementProps = {
    ...props,
    className,
    ref,
  };

  const Element = props.href ? Link : 'button';

  return (
    <Element
      {...elementProps}
    >
      {props.label}
      {props.children}
    </Element>
  );
});

ButtonTab.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node,
  multiline: PropTypes.bool,
  noClass: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

ButtonTab.defaultProps = {
  onClick: () => {},
  href: null,
  className: '',
  active: false,
  children: null,
  multiline: false,
  noClass: false,
  type: null,
  disabled: false,
  label: null,
};

export default ButtonTab;
