import { isEqual, difference, isArray } from 'underscore';

import usePrevious from './use_previous';

const useDeepCompare = (value) => {
  const previousValue = usePrevious(value);
  const hasChanged = !isEqual(value, previousValue);

  const objDiffKeys = () => {
    const keys = Object.keys(value || {});
    if (!previousValue) {
      return keys;
    }

    return keys.filter((key) => !isEqual(value[key], previousValue[key]));
  };

  const diff = () => {
    if (isArray(value)) {
      return difference(value, previousValue || []);
    }

    return objDiffKeys();
  };

  return [
    hasChanged,
    previousValue,
    diff(),
  ];
};

export default useDeepCompare;
