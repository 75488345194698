import { Children } from 'react';

const Reversible = ({ children, reverse }) => {
  if (reverse) {
    return Children.toArray(children).toReversed();
  }
  return children;
};

export default Reversible;
