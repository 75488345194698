import PropTypes from 'prop-types';
import useFeature from '../hooks/use_feature';

/*

  This component can be used to wrap another component that we want to
  render conditionally given a feature flipper key

  - children -> Component that we want to render conditionally
  - feature -> Feature Flipper key we are going to check
  - invertedCheck -> If true, we will render the children component just if
    the feature flipper key is disabled

*/
const FeatureGuardComponent = ({ children, feature, invertedCheck }) => {
  const [featureEnabled, featureResolved] = useFeature(feature);

  if (!featureResolved) return null;

  if (
    (featureEnabled && !invertedCheck)
      || (!featureEnabled && invertedCheck)
  ) return children;

  return null;
};

FeatureGuardComponent.propTypes = {
  children: PropTypes.node.isRequired,
  feature: PropTypes.string.isRequired,
  invertedCheck: PropTypes.bool,
};

FeatureGuardComponent.defaultProps = {
  invertedCheck: false,
};

export default FeatureGuardComponent;
